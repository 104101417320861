import React, { useEffect, useState } from  'react';
import checkmark from './checkmark.png';
import errorIcon from './error-icon.png';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData} from 'react-firebase-hooks/firestore';
import './VerifySuccess.css';

const firebaseConfig = {
  apiKey: "AIzaSyBu-8fTvMBgtOpMWoMpvhFpi-20AgroLHk",
  authDomain: "birdietrain-d8807.firebaseapp.com",
  projectId: "birdietrain-d8807",
  storageBucket: "birdietrain-d8807.appspot.com",
  messagingSenderId: "277078138018",
  appId: "1:277078138018:web:bc7c4288dcc13789ac4bfb",
  measurementId: "G-DGF140J85E",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);

function App() {
  const [user] = useAuthState(auth);

  return (
    <Router>
      <div className="App">
        <section>
          <Routes>
            <Route path="/" element={user ? <HomeView /> : <SignInView />} />
            <Route path="/schedule" element={user ? <ScheduleForm /> : <SignInView />} />
            <Route path="/verifyEmail" element={<VerifyEmail />} />
            <Route path="/verifySuccess" element={<VerifySuccess />} />
            <Route path="/verifyFailed" element={<VerifyFailed />} />
          </Routes>
        </section>
      </div>
    </Router>
  );

  // return (
  //   <div className="App">
  //     <section>
  //       <Routes>
  //         <Route
  //           path="/"
  //           element={
  //             <>
  //               <HomeView />
  //               <ScheduleForm />
  //             </>
  //           }
  //         />
  //         <Route path="/verifyEmail" element={<VerifyEmail />} />
  //         <Route path="/verifySuccess" element={<VerifySuccess />} />
  //       </Routes>
       
  //     </section>
  //   </div>
  // );
}

function ScheduleForm() {
  const [startDate, setStartDate] = useState(new Date());
  const [recurrence, setRecurrence] = useState("daily");

  const scheduleExecution = (event) => {
    event.preventDefault();
    // Call Firebase function to schedule execution
    console.log(
      "Execution scheduled for: ",
      startDate,
      " Recurrence: ",
      recurrence
    );
  };

  return (
    <form onSubmit={scheduleExecution}>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        showTimeSelect
        dateFormat="Pp"
      />
      <select
        value={recurrence}
        onChange={(e) => setRecurrence(e.target.value)}
      >
        <option value="hourly">Hourly</option>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
      </select>
      <button type="submit">Schedule</button>
    </form>
  );
}

function SignInView() {
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Error signing in: ", error);
    });
  };

  return (
    <div className="sign-in-container">
      <button onClick={signInWithGoogle}>Sign in with Google</button>
    </div>
  );
}

function SignOutView() {
  return (
    auth.currentUser && <button onClick={() => signOut(auth)}>Sign Out</button>
  );
}

function HomeView() {
  const coursesCollection = collection(firestore, "internalCourses");
  const q = query(coursesCollection, orderBy("createdAt"), limit(25));
  const [courses] = useCollectionData(q, { idField: "id" });

  return (
    <div>
      <div>test</div>
      {courses &&
        courses.map((course) => <Course key={course.id} course={course} />)}
    </div>
  );
}

function Course(props) {
  const { name, id } = props.course;

  return (
    <div>
      <p>{name}</p>
      <p>{id}</p>
    </div>
  );
}

function VerifyEmail() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const code = searchParams.get("code");

  useEffect(() => {
    const verifyEmail = async () => {
      // Ensure email and code are present
      if (!email || !code) {
        console.error("Email or code missing");
        setLoading(false);
        setError(true);
        return;
      }

      const apiUrl = `https://api.thebirdietrain.com/v1/verify_email?email_id=${encodeURIComponent(email)}&code=${encodeURIComponent(code)}`;

      try {
        const response = await fetch(apiUrl);
        if (response.ok) {
          const data = await response.json(); 
          console.log("Verification successful. Data: ", data);
          navigate("/verifySuccess");
        } else {
          console.error("Verification failed");
          setError(true);
        }
      } catch (error) {
        console.error("Error:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, [email, code, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    navigate("/verifyFailed");
    // return <div>Error occurred during email verification. Please contact BirdieTrain support.</div>;
  }

  return <div>Verifying...</div>;
}

function VerifySuccess() {
  return (
    <div class="container">
        <div class="header">
            <img src="http://cdn.mcauto-images-production.sendgrid.net/f7f6616d4ec3f7b0/6b556212-e95d-4b07-8534-e46ff74dbd74/757x1119.png" alt="BirdieTrain Logo"></img>
            <h2>BirdieTrain</h2>
        </div>
        <div class="content">
            <h1>Email Address Verified</h1>
            <p>Thank you for verifying your email address with BirdieTrain.</p>
            <img class="checkmark" src={checkmark} alt="Checkmark"></img>
            <p>Tee it high and let it fly!</p>
        </div>
        <div class="footer">
            <p>&copy; {new Date().getFullYear()} BirdieTrain. All rights reserved.</p>
        </div>
    </div>
  );
}

function VerifyFailed() {
  return (
    <div class="container">
        <div class="header">
            <img src="http://cdn.mcauto-images-production.sendgrid.net/f7f6616d4ec3f7b0/6b556212-e95d-4b07-8534-e46ff74dbd74/757x1119.png" alt="BirdieTrain Logo"></img>
            <h2>BirdieTrain</h2>
        </div>
        <div class="content">
            <h1>Email Address Verification Failed</h1>
            <p>Please contact BirdieTrain support for assistance.</p>
            <img class="checkmark" src={errorIcon} alt="Error"></img>
            <br/>
            <a class="support" href="mailto:support@thebirdietrain.com">support@thebirdietrain.com</a>
        </div>
        <div class="footer">
            <p>&copy; {new Date().getFullYear()} BirdieTrain. All rights reserved.</p>
        </div>
    </div>
  );
}

export default App;
